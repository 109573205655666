
.zoom-01 {
  zoom: 0.1;
}

.zoom-02 {
  zoom: 0.2;
}

.zoom-03 {
  zoom: 0.3;
}

.zoom-04 {
  zoom: 0.4;
}

.zoom-05 {
  zoom: 0.5;
}

.zoom-06 {
  zoom: 0.6;
}

.zoom-065 {
  zoom: 0.65;
}

.zoom-07 {
  zoom: 0.7;
}

.zoom-073 {
  zoom: 0.73;
}

.zoom-075 {
  zoom: 0.75;
}

.zoom-078 {
  zoom: 0.78;
}

.zoom-08 {
  zoom: 0.8;
}

.zoom-083 {
  zoom: 0.83;
}

.zoom-085 {
  zoom: 0.85;
}

.zoom-09 {
  zoom: 0.9;
}

.zoom-093 {
  zoom: 0.93;
}

.zoom-095 {
  zoom: 0.95;
}

.zoom-098 {
  zoom: 0.98;
}

/* Popup Table css */

.reservation-popup-table {
  border-collapse: collapse;
  width: 100%;
  overflow-x: hidden;
  display: block;
}

/* thead {
  background-color: #EFEFEF;
} */


.reservation-popup-head {
  display: block;
  width: 916px;
}
.reservation-popup-body {
  display: block;
  width: 916px;
}

.reservation-popup-body {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 325px;
}


.reservation-popup-th{
    min-width: 229px;
    height: 25px;
    /* border: dashed 1px lightblue; */
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 229px;
}

.reservation-popup-td{
    min-width: 229px;
    height: 25px;
    /* border: dashed 1px lightblue; */
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 229px;
}


/* Sample Processing table css */

.sample-processing-table {
  border-collapse: collapse;
  width: 100%;
  overflow-x: hidden;
  display: block;
}

/* thead {
  background-color: #EFEFEF;
} */


.sample-processing-head {
  display: block;
  /* width: 916px; */
}
.sample-processing-body {
  display: block;
  /* width: 916px; */
}

.sample-processing-body {
  overflow-y: auto;
  overflow-x: hidden;
  height: 800px;
}


.sample-processing-th{
    min-width: 120px;
    height: 25px;
    /* border: dashed 1px lightblue; */
    overflow: hidden;
    /* text-overflow: ellipsis; */
    max-width: 120px;
}
.sample-processing-tr{
  min-width: 280px !important;
  max-width: 280px !important;
}
.sample-processing-result{
  min-width: 230px !important;
  max-width: 230px !important;
}

.sample-processing-td{
    min-width: 120px;
    height: 25px;
    /* border: dashed 1px lightblue; */
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
}

.btn-primary{
    display: block;
    width: 100%;
  }
  .light-menu{
    background-color: #fff;
  }
  .add-content .add-icon{
    text-align: end;
  }
  .add-content{
    align-items: center;
    padding: 1rem;
  }
  .sideber-form-builder{
    height: 100%;
  }
  .add-content .text{
    font-size: 14px;
    line-height: 20px;
    color: rgb(38, 38, 39);
    font-weight: 500;
  }
  .add-content .add-icon .btn{
    width: 32px;
    height: 32px;
    background-color: rgb(227, 227, 227);
    padding: 0;  
    color: #000;
  }
  .col-gap{
    gap: 30px;
  }
  .question-list{
    width: 100%;
    color: #000;
    padding: 0;
    margin-bottom: 0;
  }
  
  .select-question {
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(240, 240, 240);
    cursor: pointer;
    display: flex;
    padding: 8px 16px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: inherit;
    min-height: 56px;
    transition: background-color 400ms ease-in-out 0s, border-color 400ms ease-in-out 0s, box-shadow 400ms ease-in-out 0s;
  }
  .select-child-question {
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(227, 227, 227);
    cursor: pointer;
    display: flex;
    padding: 8px 16px 8px 8px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: inherit;
    min-height: 56px;
    transition: background-color 400ms ease-in-out 0s, border-color 400ms ease-in-out 0s, box-shadow 400ms ease-in-out 0s;
  }
  .question-list-item {
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 8px 16px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: inherit;
    min-height: 56px;
    transition: background-color 400ms ease-in-out 0s, border-color 400ms ease-in-out 0s, box-shadow 400ms ease-in-out 0s;
  }
  .question-list-item.listing{
    display: block;
  }
  .question-list-item.listing ul{
    padding-left: 10px;
    margin: 0;
  }
  .question-list-item-main.child-list{
    display: flex;
    align-items: center;
  }
  .question-list-main {
    display: flex;
    flex: 1 1 0%;
    -webkit-box-align: center;
    align-items: center;
  }
  .question-list-number {
    display: flex;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    align-items: center;
  }
  .list-number {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: rgb(55, 156, 251);
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
    width: 52px;
    height: 24px;
    padding: 6px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    color: black;
    font-weight: 500;
  }
  
  .list-lebel-box {
    flex: 0 1 144px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    position: relative;
    word-break: break-word;
    margin-left: 12px;
  }
  .menu-button{
    display: none;
  }
  .menu-button .btn{
    border: none !important;
    background-color: transparent !important;
    padding: 0 !important;
    color: #000 !important;
  }
  .question-list-main:hover .menu-button{
    display: block;
  }
  .list-lebel {
    display: block;
    font-size: 12px;
    line-height: 18px;
  }
  .model-label {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: rgb(214, 92, 153);
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
    overflow: hidden;
    width: 24px;
    height: 24px;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .model-icon {
    min-width: 0px;
    min-height: 0px;
    flex-shrink: 0;
    padding-right: 8px;
  }
  .question-type{
    list-style-type: none;
    color: #000;
  }
  .question-type li{
    margin-bottom: 15px;
    cursor: pointer;
  }

  .add-test-family-main {
    padding: 0px 30px;
  }

  .add-test-table-w {
    width: 60rem;
    max-height: 600px;
  }

  .booking-quotation-table-w {
    width: 30rem;
  }
  
  .input-w-class {
    width: 69%;
  }

  
  .sales-booking-head-s {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 20px;
  }

  .booking-user-details-s {
    background-color: rgb(247, 247, 247);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;
  }

  .sample-details-head {
    /* background-color: #0E7490; */
    background-color: #0891B2;
    padding: 10px;
    border-radius: 6px;
    color: #ffffff;
  }

  .sample-main-div {
    width: 90%;
  }

  .booking-key-data {
    width: 150px;
  }

  .booking-method-details {
    border: 2px solid #0891B2;
    border-radius: 5px;
    padding: 10px;
    height: 65px;
  }
  .basic-multi-select [type='text']:focus, .basic-multi-select [type='email']:focus, .basic-multi-select [type='url']:focus, .basic-multi-select [type='password']:focus, .basic-multi-select [type='number']:focus, .basic-multi-select [type='date']:focus, .basic-multi-select [type='datetime-local']:focus, .basic-multi-select [type='month']:focus, .basic-multi-select [type='search']:focus, .basic-multi-select [type='tel']:focus, .basic-multi-select [type='time']:focus, .basic-multi-select [type='week']:focus, .basic-multi-select [multiple]:focus, .basic-multi-select textarea:focus, .basic-multi-select select:focus{
    box-shadow: none
  }
  .css-t3ipsp-control:hover, .css-t3ipsp-control{
    border-color: #0891B2 !important;
  }
  .form-wrap.form-builder .frmb-control .icon-autocomplete, .form-wrap.form-builder .frmb-control .icon-button, .form-wrap.form-builder .frmb-control .icon-checkbox-group, .form-wrap.form-builder .frmb-control .icon-file, .form-wrap.form-builder .frmb-control .icon-hidden, .form-wrap.form-builder .frmb-control .icon-paragraph {
    display: none;
  }
  .file_uploader .haYSpZ{
    border: dashed 2px #c6c6c6;
  }
  .file_uploader .haYSpZ svg path{
    fill: #91959c !important;
    height: 80px;
  }
  .file_uploader .haYSpZ .sc-iBYQkv .sc-ftTHYK span {
    color: #1e91b2;
  }
  .multiSelectContainer input:focus{
    box-shadow: none;
  }
  .optionListContainer{
    position: relative !important;
  }
  
  .highlightOption, .multiSelectContainer li:hover, .chip{
    background: #0891B2 !important;
  }
  .card-sec:hover{
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -2px;
    background-color: white;
  }
  .toastContainer{
    z-index: 999;
  }
  .tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    height: auto;
    background-color: #0891B2;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 15px;
    position: absolute;
    z-index: 1;
    bottom: 25px;
    left: 0;
    margin-left: -134px;
    white-space: break-spaces;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .tooltip .tooltiptext2 {
    visibility: hidden;
    width: 200px;
    height: auto;
    background-color: #bedee9;
    color: #141414;
    text-align: center;
    border-radius: 5px;
    padding: 15px;
    position: absolute;
    z-index: 1;
    bottom: 25px;
    left: 0;
    margin-left: -134px;
    white-space: break-spaces;
  }
  
  .tooltip:hover .tooltiptext2 {
    visibility: visible;
  }


  .tooltip .tooltiptext3 {
    visibility: hidden;
    font-size: 12px;
    width: 100px;
    height: auto;
    background-color: #d3d5d5;
    color: #141414;
    text-align: center;
    border-radius: 5px;
    padding: 15px;
    position: absolute;
    z-index: 1;
    bottom: 25px;
    left: 0;
    white-space: break-spaces;
  }
  
  .tooltip:hover .tooltiptext3 {
    visibility: visible;
  }